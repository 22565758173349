var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"fill-height pt-0",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"px-8 pt-16",attrs:{"cols":"12","md":"11"}},[_c('v-data-table',{staticClass:"elevation-1 my-10",attrs:{"headers":_vm.headers,"items":_vm.applications,"single-expand":"","expanded":_vm.expanded,"item-key":"id","sort-by":"date","show-expand":"","items-per-page":15},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.applicant",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.first)+" "+_vm._s(item.last)+" ")]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.startDate))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.remove(item)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete-outline")])],1)]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"pb-8",attrs:{"colspan":_vm.headers.length}},[_c('v-row',{staticClass:"pa-5"},[_c('v-col',[_c('v-list',{attrs:{"two-line":""}},_vm._l((_vm.list),function(i){return _c('v-list-item-content',{key:i.i},[_c('v-list-item-title',{staticClass:"title"},[_vm._v(_vm._s(i.title))]),_c('v-list-item-subtitle',[(typeof item[i.value] == 'boolean')?_c('v-chip',{attrs:{"dark":"","outlined":"","color":item[i.value] ? 'success' : 'red'}},[_vm._v(" "+_vm._s(item[i.value] === true ? "Yes" : "No")+" ")]):_c('span',{staticClass:"text-wrap"},[_vm._v(" "+_vm._s(item[i.value])+" ")])],1)],1)}),1),_c('v-btn',{attrs:{"color":"primary darken-2","disabled":item.resume == undefined || item.resume == ''},on:{"click":function($event){$event.preventDefault();return _vm.downloadResumePdf(item.resume)}}},[_vm._v(" "+_vm._s(item.resume == undefined || item.resume == "" ? "No Resume" : "View Resume")+" ")])],1)],1)],1)]}}])})],1)],1),(_vm.selected != null)?_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"600"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"secondary","dark":""}},[_vm._v(" Remove Application ")]),_c('v-card-text',[_c('div',{staticClass:"display-1 pt-5"},[_vm._v(" "+_vm._s(_vm.selected.first)+" "+_vm._s(_vm.selected.last)+" ")])]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v("Close")]),_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":function($event){return _vm.removeApp(_vm.selected.id)}}},[_vm._v("Delete")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }