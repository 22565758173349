<template>
  <div>
    <v-row class="fill-height pt-0" justify="center">
      <v-col cols="12" md="11" class="px-8 pt-16">
        <v-data-table
          :headers="headers"
          :items="applications"
          single-expand
          :expanded.sync="expanded"
          item-key="id"
          sort-by="date"
          show-expand
          :items-per-page="15"
          class="elevation-1 my-10"
        >
          <template v-slot:item.applicant="{ item }">
            {{ item.first }} {{ item.last }}
          </template>

          <template v-slot:item.startDate="{ item }">
            {{ formatDate(item.startDate) }}
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn @click="remove(item)" icon>
              <v-icon color="red">mdi-delete-outline</v-icon>
            </v-btn>
          </template>

          <template v-slot:expanded-item="{ item }">
            <td :colspan="headers.length" class="pb-8">
              <v-row class="pa-5">
                <v-col>
                  <v-list two-line>
                    <v-list-item-content v-for="i in list" :key="i.i">
                      <v-list-item-title class="title">{{
                        i.title
                      }}</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-chip
                          dark
                          v-if="typeof item[i.value] == 'boolean'"
                          outlined
                          :color="item[i.value] ? 'success' : 'red'"
                        >
                          {{ item[i.value] === true ? "Yes" : "No" }}
                        </v-chip>
                        <span v-else class="text-wrap">
                          {{ item[i.value] }}
                        </span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list>
                  <v-btn
                    color="primary darken-2"
                    :disabled="item.resume == undefined || item.resume == ''"
                    @click.prevent="downloadResumePdf(item.resume)"
                  >
                    {{
                      item.resume == undefined || item.resume == ""
                        ? "No Resume"
                        : "View Resume"
                    }}
                  </v-btn>
                </v-col>
              </v-row>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="deleteDialog"
      v-if="selected != null"
    >
      <v-card>
        <v-toolbar color="secondary" dark> Remove Application </v-toolbar>
        <v-card-text>
          <div class="display-1 pt-5">
            {{ selected.first }} {{ selected.last }}
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="deleteDialog = false">Close</v-btn>
          <v-btn text @click="removeApp(selected.id)" color="error"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { applicationsCollection, storage } from "../firebase/credentials";
import moment from "moment";
export default {
  data() {
    return {
      applications: [],
      expanded: [],
      deleteDialog: false,
      selected: null,
      headers: [
        { text: "Applied", value: "date", width: "120" },
        { text: "Applicant", value: "applicant" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Position", value: "positionType" },
        { text: "Available", value: "startDate" },
        { text: "", value: "actions", width: "25" },
        { text: "", value: "data-table-expand" },
      ],
      list: [
        {
          title: "Will you work daily overtime on occasion if necessary?",
          value: "overtime",
        },
        {
          title: "Will you work extra days in the week if necessary?",
          value: "extraDays",
        },
        {
          title:
            "Do you have any on-going obligations or other personal commitments that would affect your work schedule?",
          value: "obligations",
        },
        { title: "Are you 18 years old?", value: "age" },
        {
          title:
            "If hired, can you furnish proof that you are eligible to work in the U.S.?",
          value: "eligible",
        },
        {
          title: "Describe your prior work experience related to this work",
          value: "experience",
        },
        {
          title:
            "List job benefits - other than wages - you expect or want in order of importance",
          value: "benefits",
        },
        {
          title:
            "Describe any formal schooling or training related to this work",
          value: "schooling",
        },
        {
          title:
            "List any special skills you may have (computer, machine, operation etc.)",
          value: "skills",
        },
      ],
    };
  },
  methods: {
    formatDate(date) {
      return moment(date).format("M/D/YYYY");
    },
    remove(item) {
      this.selected = item;
      this.deleteDialog = true;
    },
    async removeApp(id) {
      await applicationsCollection.doc(id).delete();
      this.deleteDialog = false;
    },
    downloadResumePdf(resume) {
      const resumeRef = storage.ref(resume);
      resumeRef
        .getDownloadURL()
        .then((url) => {
          window.open(url);
        })
        .catch((error) => {
          // Handle any errors
          switch (error.code) {
            case "storage/object-not-found":
              // File doesn't exist
              break;

            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;

            case "storage/canceled":
              // User canceled the upload
              break;

            case "storage/unknown":
              // Unknown error occurred, inspect the server response
              break;
            default:
              break;
          }
        });
    },
  },
  async mounted() {
    let result = await applicationsCollection;
    result.onSnapshot((a) => {
      this.applications = [];
      a.forEach((p) => {
        let obj = p.data();
        obj.id = p.id;
        this.applications.push(obj);
      });
    });
  },
};
</script>

<style lang="scss" scoped></style>
